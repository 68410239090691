import * as React from "react"

const useSnapshot = ref => {
  const [loading, setLoading] = React.useState(true)
  const [data, setData] = React.useState<any>({})

  React.useEffect(() => {
    return ref.onSnapshot(snapshot => {
      if (snapshot.data) {
        setData({ ...snapshot.data(), id: snapshot.id })
        setLoading(false)
      } else if (snapshot.docs) {
        setData(
          snapshot.docs.map(doc => {
            return {
              ...doc.data(),
              id: doc.id,
            }
          })
        )
        setLoading(false)
      } else {
        setData(null)
        setLoading(false)
      }
    })
  }, [])

  return { data, loading }
}

export default useSnapshot
