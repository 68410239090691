import * as React from "react"
import styled from "styled-components"
import { Headline1 } from "./rmwc/Typography/Typography"
import { TabBar } from "@rmwc/tabs"

export const DetailWrapper = styled.div`
  display: flex;
  flex: 1;
`

export const Content = styled.div`
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.05), 0 4px 24px 2px rgba(0, 0, 0, 0.1);
  flex: 1;
`

export const DetailHeader = styled.div`
  margin-bottom: 32px;
  padding: 0 48px;
`

export const MainContent = styled.div`
  display: flex;
  justify-content: space-between;
  padding-top: 67px;
  align-items: center;
`

export const DisplayName = styled(Headline1)`
  color: var(--mdc-theme-primary);
`

export const StyledTabBar = styled(TabBar)`
  margin-top: 40px;
`

export const DetailContent = styled.div`
  padding: 0 48px;
`

export const DetailList = styled.div``

export const DetailListHeader = styled.div`
  display: flex;
  font-size: 12px;
  color: ${p => p.theme.grey500};
  height: 32px;
  align-items: center;
`
export const DetailListItem = styled.div`
  display: flex;
  align-items: center;
  height: 64px;
  cursor: pointer;

  &:hover {
    background-color: rgba(0, 0, 0, 0.03);
  }
`
export const DetailListCell = styled.div<{ right?: boolean; flex?: number }>`
  flex: ${p => p.flex || 1};
  display: flex;
  justify-content: ${p => p.right && "flex-end"};
`
