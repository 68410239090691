import * as React from "react"
import { SFC } from "react"
import styled from "styled-components"
import { Headline2 } from "../rmwc/Typography"
import { Seperator } from "../rmwc/Seperator"
import { IconButton } from "../rmwc/IconButton"

const CardStyle = styled.div<{ height?: string }>`
  position: relative;
  width: 100%;
  background-color: white;
  border-radius: 4px;
  box-shadow: 0 0 3px rgba(0, 0, 0, 0.12);
  padding: 32px;
  box-shadow: 0 10px 20px -5px rgba(0, 0, 0, 0.08);
  margin-bottom: 24px;
  height: ${props => props.height};

  .mdc-text-field-helper-line {
    margin-bottom: 12px;
  }
`

const CardHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

const CardHeadline = styled(Headline2)`
  color: var(--mdc-theme-primary);
`

const Action = styled(IconButton)`
  color: ${p => p.theme.grey300};
  cursor: pointer;
`

interface Props {
  onAction?: () => void
  onCancel?: () => void
  active?: boolean
  actionIcon?: string
  title?: string
  height?: string
}

export const Card: SFC<Props> = props => {
  const {
    onAction,
    onCancel,
    active,
    actionIcon,
    title,
    children,
    height,
  } = props

  function handleChange(event) {
    if (active) {
      onCancel()
    } else {
      onAction()
    }
  }

  return (
    <CardStyle height={height}>
      {title && (
        <>
          <CardHeader>
            <CardHeadline>{title}</CardHeadline>
            {onAction && (
              <Action
                checked={active}
                onChange={handleChange}
                onIcon={actionIcon || "close"}
                icon={actionIcon || "edit"}
              />
            )}
          </CardHeader>
          <Seperator />
        </>
      )}
      {children}
    </CardStyle>
  )
}
