import * as React from "react"
import { RouteComponentProps } from "@reach/router"
import UserView from "../users/User"
import useFirestore from "../../hooks/useFirestore"

const User: React.FC<RouteComponentProps<{ id?: string }>> = props => {
  const { data: user, loading } = useFirestore({
    collection: "users",
    find: props.id,
  })

  if (loading) return null

  return (
    <UserView user={user} location={props.location}>
      {props.children}
    </UserView>
  )
}

export default User
