import * as React from "react"
import styled from "styled-components"
import { Button } from "../rmwc/Button"

interface FormProps {
  onSubmit?: (event: any) => Promise<void> | void
  inline?: boolean
  submitText?: string
  active?: boolean
  disabled?: boolean
  message?: string
  messageType?: string
}

const Wrapper = styled.form<{ inline?: boolean }>`
  display: flex;
  flex-direction: ${p => (p.inline ? "row" : "column")};
  position: relative;
`

export const Message = styled.div<FormProps>`
  color: ${props => props.theme[props.messageType]};
  font-size: 13px;
  font-weight: 700;
  margin-bottom: 24px;
  text-align: center;
`

export const Form: React.FC<FormProps> = props => {
  const {
    onSubmit,
    children,
    submitText,
    disabled,
    inline,
    message,
    messageType,
    active,
  } = props

  function handleSubmit(event) {
    event.preventDefault()
    if (onSubmit) {
      onSubmit(event)
    }
  }

  return (
    <Wrapper onSubmit={handleSubmit} inline={inline}>
      <Message messageType={messageType} data-testid="message">
        {message}
      </Message>
      {children}
      <br />
      {submitText && active && (
        <>
          <Button disabled={disabled}>{submitText}</Button>
          <br />
        </>
      )}
    </Wrapper>
  )
}
