import * as React from "react"
import styled from "styled-components"

import { Button as MaterialButton, ButtonProps } from "@rmwc/button"
import "@material/button/dist/mdc.button.css"
import { MergeInterfacesT, ComponentProps } from "@rmwc/types"

interface Props {
  fullWidth?: boolean
  buttonSize?: "large"
  variant?: "outlined" | "text"
}

export const WrappedButton = styled(MaterialButton as React.FunctionComponent<
  MergeInterfacesT<Props, ButtonProps>
>)<Props>`
  width: ${props => props.fullWidth && "100%"};
  height: ${props => props.buttonSize === "large" && "56px !important"};
`

export const Button: React.FC<Props & ButtonProps & ComponentProps> = props => {
  const variant = props.variant || "unelevated"

  return (
    <WrappedButton
      unelevated={variant == "unelevated"}
      outlined={variant == "outlined"}
      {...props}
    >
      {props.children}
    </WrappedButton>
  )
}
