import * as React from "react"
import { RouteComponentProps } from "@reach/router"
import useFirestore from "../../hooks/useFirestore"
import { Release, PullContext, TitleSection } from "../Comic"
import * as moment from "moment"
import { UserContext } from "../users/User"
import { graphql } from "gatsby"
import { Headline1, Body1 } from "../../components/rmwc/Typography"
import ButtonSubscribe from "../../components/ButtonSubscribe"

const UserComic: React.FC<RouteComponentProps<{
  seriesCode: string
}>> = props => {
  const { seriesCode } = props
  const user = React.useContext(UserContext)
  const pullId = `${user.uid}-${seriesCode}`

  const { data: issues, loading } = useFirestore({
    collection: "issues",
    where: [
      ["shipDate", ">=", moment().valueOf()],
      ["seriesCode", "==", seriesCode],
    ],
  })

  const { data: pull, loading: pullLoading } = useFirestore({
    collection: "pulls",
    find: pullId,
  })

  if (loading || pullLoading) return null
  const groupedIssues = groupBy(issues, "issue")
  const firstKey = Object.keys(groupedIssues)[0]
  const comic = groupedIssues[firstKey][0]

  return (
    <div>
      <PullContext.Provider value={{ pull, loading }}>
        <TitleSection>
          <div>
            <Headline1 color="primary">{comic.title}</Headline1>
            {!loading && (
              <>
                {pull?.active && (
                  <Body1>
                    You added to your list on{" "}
                    {moment(pull.createdOn).format("MMMM Do, YYYY")}
                  </Body1>
                )}
                {pull && !pull.active && (
                  <Body1>
                    You cancelled on{" "}
                    {moment(pull.cancelledOn).format("MMMM Do, YYYY")}
                  </Body1>
                )}
              </>
            )}
          </div>
          <ButtonSubscribe
            seriesCode={comic.seriesCode}
            title={comic.title}
            currentUser={user}
          />
        </TitleSection>
        {Object.keys(groupedIssues).map(key => (
          <Release issues={groupedIssues[key]} />
        ))}
      </PullContext.Provider>
    </div>
  )
}

export default UserComic

var groupBy = function(xs, key) {
  return xs.reduce(function(rv, x) {
    ;(rv[x[key]] = rv[x[key]] || []).push(x)
    return rv
  }, {})
}
