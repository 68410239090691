import * as React from "react"
import { RouteComponentProps } from "@reach/router"
import moment from "moment"
import { navigate } from "gatsby"
import {
  StyledList,
  ListHeadline,
  Layout,
  SearchContainer,
  SearchInput,
  ListWrapper,
  ListItem,
  Text,
} from "../../components/ListElements"
import { Content } from "../../components/DetailElements"
import { NOW } from "../../core/constants"

const FOCs: React.FC<RouteComponentProps> = ({ children }) => {
  const [dateInput, setDateInput] = React.useState(new Date().toString())
  const [loaded, setLoaded] = React.useState(false)

  React.useEffect(() => {
    const dateInputMonday = moment(dateInput)
      .startOf("week")
      .day(1)
      .valueOf()

    if (loaded) {
      navigate(`/admin/foc/${dateInputMonday}`)
    }
    setLoaded(true)
  }, [dateInput])

  const weeks = []
  for (let i = 0; i < 3; i++) {
    weeks.push(
      moment(NOW)
        .subtract(i, "week")
        .startOf("week")
        .day(1)
        .valueOf()
    )
  }

  console.log(weeks)

  return (
    <>
      <StyledList>
        <ListHeadline>Orders</ListHeadline>
        <Layout>
          <SearchContainer>
            <SearchInput
              placeholder="Select Date"
              value={dateInput}
              type="date"
              onChange={event => setDateInput(event.target.value.toString())}
            />
          </SearchContainer>

          <ListWrapper>
            {weeks.map(week => (
              <ListItem to={`/admin/foc/${week}`} key={week}>
                <Text>{moment(week).format("MMM Do YYYY")}</Text>
              </ListItem>
            ))}
          </ListWrapper>
        </Layout>
      </StyledList>

      <Content>{children}</Content>
    </>
  )
}

export default FOCs
