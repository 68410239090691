import * as React from "react"
import { RouteComponentProps, navigate } from "@reach/router"
import useFirestore, { useFirestoreRef } from "../../hooks/useFirestore"
import {
  DetailHeader,
  MainContent,
  DisplayName,
  DetailContent,
  DetailList,
  DetailListHeader,
  DetailListCell,
  DetailListItem,
} from "../../components/DetailElements"
import { Container } from "../../components/Container"
import { coverConvert } from "../../core/Utils"
import {
  FlexContainer,
  FlexList,
  ListItemMetaGap,
} from "../../components/ListElements"
import { format } from "date-fns"
import { IconButton } from "../../components/rmwc/IconButton"
import { Button } from "../../components/rmwc/Button"
import { useSet } from "../../hooks/useMutation"
import { pluck } from "../../core/Utils"
import { Icon } from "@rmwc/icon"
import { firestore } from "../../core/firebase"
import { baseImagePath } from "../Comic"
import styled from "styled-components"

const Thumbnail = styled.img`
  width: 120px;
`

const ListWrapper = styled.div`
  display: flex;
  align-items: center;
  & ${Thumbnail} {
    margin-right: 16px;
  }
`

const ReadyIssue: React.FC<RouteComponentProps & { stockNumber?: string }> = ({
  stockNumber,
}) => {
  const { data: readyIssue, loading } = useFirestore({
    collection: "orders",
    find: stockNumber,
  })

  if (loading || !readyIssue) return null

  const handleAddAll = () => {
    const issue = pluck(
      readyIssue,
      "cover",
      "diamondNumber",
      "stockNumber",
      "issue",
      "title",
      "price"
    )

    for (const user of readyIssue.users) {
      firestore
        .collection("inboxitems")
        .doc(`${user.uid}-${readyIssue.stockNumber}`)
        .set({
          ...issue,
          copies: user.copies,
          uid: user.uid,
        })
    }
  }

  const { title, issue, cover } = readyIssue

  // readyIssue.users.sort((a, b) => (a.box > b.box ? 1 : -1))
  return (
    <>
      <DetailHeader>
        <Container>
          <MainContent>
            <ListWrapper>
              <Thumbnail src={`${baseImagePath}/${stockNumber}.jpg`} />
              <DisplayName>
                {title} #{issue} {coverConvert(cover)}
              </DisplayName>
            </ListWrapper>
            <Button onClick={handleAddAll} trailingIcon="add">
              Add all
            </Button>
          </MainContent>
        </Container>
      </DetailHeader>
      <DetailContent>
        <Container>
          <DetailList>
            <DetailListHeader>
              <DetailListCell flex={2}>User</DetailListCell>
              <DetailListCell>Box</DetailListCell>
              <DetailListCell>copies</DetailListCell>
              <DetailListCell right>Action</DetailListCell>
            </DetailListHeader>
            {Object.entries(readyIssue.users).map(([uid, user]) => (
              <ReadyIssueUserListItem user={user} issue={readyIssue} />
            ))}
          </DetailList>
        </Container>
      </DetailContent>
    </>
  )
}

export default ReadyIssue

const ReadyIssueUserListItem: React.FC<{ user: any; issue: any }> = ({
  user,
  issue,
}) => {
  const inboxIssue = pluck(
    issue,
    "cover",
    "diamondNumber",
    "stockNumber",
    "issue",
    "title",
    "price"
  )
  const [addToBox, { loading: mutationLoading }] = useSet({
    collection: "inboxitems",
    data: {
      ...inboxIssue,
      copies: user.copies,
      uid: user.uid,
    },
  })

  const { data: inboxitem, loading } = useFirestore({
    collection: "inboxitems",
    find: `${user.uid}-${issue.stockNumber}`,
  })

  const handleAdd = () => {
    addToBox(`${user.uid}-${issue.stockNumber}`)
  }

  if (loading) return null

  return (
    <DetailListItem key={issue.stockNumber}>
      <DetailListCell flex={2}>{user.displayName}</DetailListCell>
      <DetailListCell>{user.box}</DetailListCell>
      <DetailListCell>{user.copies}</DetailListCell>
      <DetailListCell right>
        <Button trailingIcon="add" onClick={handleAdd} disabled={inboxitem}>
          {inboxitem ? "Added" : "Add to box"}
        </Button>
      </DetailListCell>
    </DetailListItem>
  )
}
