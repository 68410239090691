import * as React from "react"
import { RouteComponentProps, Link, navigate } from "@reach/router"
import { UserContext } from "./User"
import useFirestore from "../../hooks/useFirestore"
import { List, SimpleListItem } from "@rmwc/list"
import { format } from "date-fns"
import styled from "styled-components"
import { Headline2 } from "../../components/rmwc/Typography"
import { Button } from "../../components/rmwc/Button"
import { FlexContainer, FlexList } from "../../components/ListElements"

const Invoices: React.FC<RouteComponentProps> = props => {
  const { children } = props
  const user = React.useContext(UserContext)

  const { data: invoices, loading } = useFirestore({
    collection: "invoices",
    where: [["uid", "==", user.uid]],
    orderBy: ["date", "desc"],
  })

  if (loading) return null

  return (
    <>
      <FlexContainer justify="space-between">
        <Headline2 color="primary">Invoices</Headline2>
        <Button onClick={() => navigate("create")}>Create invoice</Button>
      </FlexContainer>

      <FlexContainer>
        <FlexList twoLine>
          {invoices.map(invoice => (
            <Link to={`${invoice.id}`} key={invoice.id}>
              <SimpleListItem
                text={format(invoice.date, "MMMM dd, yyyy")}
                secondaryText={`${invoice.lineItems.length} items`}
                meta={`$${invoice.total}`}
              />
            </Link>
          ))}
        </FlexList>
        {children}
      </FlexContainer>
    </>
  )
}

export default Invoices
