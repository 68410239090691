import * as React from "react"
import { RouteComponentProps } from "@reach/router"
import useFirestore from "../../hooks/useFirestore"
import moment from "moment"
import { Link } from "gatsby"
import {
  StyledList,
  ListHeadline,
  Layout,
  SearchContainer,
  SearchInput,
  ListWrapper,
  ListItem,
  Text,
  SearchDate,
} from "../../components/ListElements"
import { Icon } from "@rmwc/icon"
import { IconButton } from "../../components/rmwc/IconButton"
import { FUNCTION_PATH, NOW } from "../../core/constants"
import { Content } from "../../components/DetailElements"
import { coverConvert } from "../../core/Utils"

const ReadyIssues: React.FC<RouteComponentProps> = ({ children }) => {
  const [searchInput, setSearchInput] = React.useState("")
  const [selectedDate, setSelectedDate] = React.useState(
    moment(NOW)
      .startOf("week")
      .day(3)
      .valueOf()
  )

  const { data: orders, loading: loading } = useFirestore({
    collection: "orders",
    where: [["shipDate", "==", selectedDate]],
    key: selectedDate,
  })

  const updateReadyIssues = () => {
    fetch(`${FUNCTION_PATH}/createOrders`)
  }

  const firstIssue = orders[0]

  return (
    <>
      <StyledList>
        <ListHeadline
          meta={<IconButton icon="refresh" onClick={updateReadyIssues} />}
        >
          Ready Issues
        </ListHeadline>
        <Layout>
          <SearchContainer>
            <SearchDate
              type="date"
              value={moment(selectedDate).format("YYYY-MM-DD")}
              onChange={event =>
                setSelectedDate(
                  moment(event.target.value)
                    .startOf("week")
                    .day(3)
                    .valueOf()
                )
              }
            />
          </SearchContainer>
          <SearchContainer>
            <SearchInput
              placeholder="Search issues"
              value={searchInput}
              onChange={event => setSearchInput(event.target.value)}
            />
            <Icon icon="search" />
          </SearchContainer>

          {!loading && (
            <ListWrapper>
              {orders
                .filter(issue =>
                  issue.title.toLowerCase().includes(searchInput.toLowerCase())
                )
                .map(issue => (
                  <ListItem
                    to={`/admin/ready-issues/${issue.stockNumber}`}
                    key={issue.stockNumber}
                  >
                    <Text>
                      {issue.title} #{issue.issue} {coverConvert(issue.cover)}
                    </Text>
                    <Text>{issue.totalcopies}</Text>
                  </ListItem>
                ))}
            </ListWrapper>
          )}
        </Layout>
      </StyledList>

      <Content>{children}</Content>
    </>
  )
}

export default ReadyIssues
