import * as React from "react"
import styled from "styled-components"
import { Headline1 } from "./rmwc/Typography/Typography"
import { TabBar, Tab } from "@rmwc/tabs"
import { navigate } from "@reach/router"
import { UserContext } from "../views/users/User"
import { Container } from "./Container"
import {
  DetailHeader,
  MainContent,
  DisplayName,
  StyledTabBar,
} from "./DetailElements"

const UserHeader = props => {
  const { location } = props
  const [activeTabIndex, setActiveTabIndex] = React.useState(0)
  const user = React.useContext(UserContext)

  const locationPaths = location.pathname.split("/")
  const section = locationPaths.slice(-1)[0]
  const tabPaths = ["profile", "pulls", "invoices", "activity"]

  React.useEffect(() => {
    locationPaths.forEach(path => {
      const foundPath = tabPaths.find(tabpath => tabpath == path)
      if (foundPath) {
        setActiveTabIndex(tabPaths.indexOf(foundPath))
      }
    })
  }, [section])

  const onTabActivate = event => {
    setActiveTabIndex(event.target.index)
  }

  let userIdIndex = locationPaths.indexOf(user.uid)
  if (userIdIndex == -1) {
    userIdIndex = locationPaths.indexOf("user")
  }

  const newPath = locationPaths.reduce((path, partial, index) => {
    return index <= userIdIndex ? path + partial + "/" : path
  }, "")

  return (
    <DetailHeader>
      <Container>
        <MainContent>
          <DisplayName>{user.displayName}</DisplayName>
        </MainContent>

        <StyledTabBar
          onActivate={onTabActivate}
          activeTabIndex={activeTabIndex}
        >
          <Tab restrictIndicator onClick={() => navigate(newPath + "profile/")}>
            Profile
          </Tab>
          <Tab restrictIndicator onClick={() => navigate(newPath + "pulls/")}>
            Pulls
          </Tab>
          <Tab
            restrictIndicator
            onClick={() => navigate(newPath + "invoices/")}
          >
            Invoices
          </Tab>
          <Tab restrictIndicator onClick={() => navigate(newPath + "history/")}>
            History
          </Tab>
        </StyledTabBar>
      </Container>
    </DetailHeader>
  )
}

export default UserHeader
