import * as React from "react"
import UserHeader from "../../components/UserHeader"
import { RouteComponentProps } from "@reach/router"
import { DetailContent } from "../../components/DetailElements"

export const UserContext = React.createContext(null)

const User: React.FC<RouteComponentProps & { user: any }> = props => {
  const { user, children, location } = props

  return (
    <UserContext.Provider value={user}>
      <UserHeader location={location} />
      <DetailContent>{children}</DetailContent>
    </UserContext.Provider>
  )
}

export default User
