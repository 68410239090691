import * as React from "react"
import { RouteComponentProps } from "@reach/router"
import styled from "styled-components"

import { Card } from "../../components/Card"
import { Container } from "../../components/Container"
import { UpdateAddress } from "../../components/forms/UpdateAddress"
import { UpdatePayment } from "../../components/forms/UpdatePayment"
import { UpdateProfile } from "../../components/forms/UpdateProfile"
import { UpdatePassword } from "../../components/forms/UpdatePassword"
import { UserContext } from "./User"

export const CardContainer = styled(Container)`
  display: flex;
  flex-wrap: wrap;
`

export const CardCell = styled.div`
  flex: 1;
`
export const CardSpacer = styled.div`
  margin: 0 8px;
`

const Profile: React.FC<RouteComponentProps> = props => {
  const [active, setactive] = React.useState("")
  const user = React.useContext(UserContext)

  return (
    <CardContainer>
      <CardCell>
        <Card
          title="Payment"
          active={active === "payment"}
          onAction={() => setactive("payment")}
          onCancel={() => setactive("")}
        >
          <UpdatePayment
            user={user}
            active={active === "payment"}
            onCancel={() => setactive("")}
          />
        </Card>

        <Card
          title="Profile"
          active={active === "profile"}
          onAction={() => setactive("profile")}
          onCancel={() => setactive("")}
        >
          <UpdateProfile
            user={user}
            active={active === "profile"}
            onCancel={() => setactive("")}
          />
        </Card>
      </CardCell>

      <CardSpacer />

      <CardCell>
        <Card
          title="Address"
          active={active === "address"}
          onAction={() => setactive("address")}
          onCancel={() => setactive("")}
        >
          <UpdateAddress
            user={user}
            active={active === "address"}
            onCancel={() => setactive("")}
          />
        </Card>
      </CardCell>

      <CardSpacer />

      <CardCell>
        <Card
          title="Password"
          active={active === "password"}
          onAction={() => setactive("password")}
          onCancel={() => setactive("")}
        >
          <UpdatePassword
            user={user}
            active={active === "password"}
            onCancel={() => setactive("")}
          />
        </Card>
      </CardCell>
    </CardContainer>
  )
}

export default Profile
