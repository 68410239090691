import * as React from "react"
import styled from "styled-components"

import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  DialogButton,
} from "@rmwc/dialog"
import "@material/dialog/dist/mdc.dialog.css"

import {
  DataTable,
  DataTableContent,
  DataTableHead,
  DataTableRow,
  DataTableHeadCell,
  DataTableBody,
  DataTableCell,
  DataTableCellProps,
} from "@rmwc/data-table"
import "@rmwc/data-table/data-table.css"

import { Button } from "../../components/rmwc/Button"
import { Subhead1, Body2, Headline2 } from "../../components/rmwc/Typography"
import { Container } from "../../components/Container"
import { Card } from "../../components/Card"
import { navigate, RouteComponentProps } from "@reach/router"
import useCreateInvoice, { CreateInvoiceProvider } from "./CreateInvoiceState"
import { Seperator } from "../../components/rmwc/Seperator"
import { IconButton } from "../../components/rmwc/IconButton"
import { TextField } from "@rmwc/textfield"
import { useAdd, useSet } from "../../hooks/useMutation"
import { UserContext } from "./User"

const HeaderActionRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;
  width: 100%;
`

interface CreateInvoiceProps {
  open?: boolean
  onClose?: () => void
}

const CreateInvoice: React.FC<RouteComponentProps &
  CreateInvoiceProps> = props => {
  return (
    <CreateInvoiceProvider>
      <CreateInvoiceForm {...props} />
    </CreateInvoiceProvider>
  )
}

const CreateInvoiceForm: React.FC<CreateInvoiceProps> = props => {
  const user = React.useContext(UserContext)
  const { state, loading, dispatch } = useCreateInvoice()
  const { lineItems, displayConfirmation } = state
  const [saveDraftMutation, { error }] = useSet({
    collection: "draftInvoices",
    data: {
      lineItems: state.lineItems,
    },
  })
  const saveDraft = async () => {
    if (lineItems.length == 0) {
      navigate("./")
      return
    }

    saveDraftMutation(user.uid)
    navigate("./")
  }

  if (loading) return null

  return (
    <Container>
      <ConfirmCharge display={displayConfirmation} />

      <Card
        title="Create invoice"
        onAction={() => saveDraft()}
        actionIcon="save"
      >
        <HeaderActionRow>
          <Headline2>Total issues: {state.total.totalCount}</Headline2>
          <Button
            variant="outlined"
            icon="add"
            onClick={() => dispatch({ type: "addItem" })}
          >
            Add line item
          </Button>
        </HeaderActionRow>
        <DataTable style={{ width: "100%" }}>
          <DataTableContent style={{ width: "100%" }}>
            <DataTableHead>
              <DataTableRow>
                <DataTableHeadCell alignStart>Title</DataTableHeadCell>
                <DataTableHeadCell alignMiddle>Price</DataTableHeadCell>
                <DataTableHeadCell alignMiddle>Copies</DataTableHeadCell>
                <DataTableHeadCell alignMiddle>Total</DataTableHeadCell>
                <DataTableHeadCell alignEnd></DataTableHeadCell>
              </DataTableRow>
            </DataTableHead>
            <DataTableBody>
              {lineItems.map((item, index) => (
                <LineItem key={item + " - " + index} item={item} />
              ))}
            </DataTableBody>
          </DataTableContent>
        </DataTable>

        <Total />
      </Card>
    </Container>
  )
}

/////////////////////////////////////////////////////
/////////////////////////////////////////////////////

// CONFIRM CHAGE

/////////////////////////////////////////////////////
/////////////////////////////////////////////////////

const ConfirmCharge = ({ display }) => {
  const { createCharge, dispatch, state } = useCreateInvoice()

  if (!!state.invoiceId) {
    navigate(`./${state.invoiceId}`)
  }

  return (
    <Dialog
      open={display}
      onClose={() =>
        dispatch({ type: "displayConfirmation", displayConfirmation: false })
      }
    >
      {!state.invoiceId && (
        <>
          <DialogTitle>Confirm charge</DialogTitle>
          <DialogContent>
            This will charge the user ${state.total.total}. Are you sure?
            {state.error && <Body2 color="error">{state.error}</Body2>}
          </DialogContent>
          <DialogActions>
            <DialogButton action="cancel">Cancel</DialogButton>
            <DialogButton
              unelevated
              isDefaultAction
              onClick={() => createCharge()}
              disabled={state.processingCharge}
            >
              Confirm
            </DialogButton>
          </DialogActions>
        </>
      )}
    </Dialog>
  )
}

/////////////////////////////////////////////////////
/////////////////////////////////////////////////////

// LINE ITEM

/////////////////////////////////////////////////////
/////////////////////////////////////////////////////

interface InvoiceRowProps {
  border?: boolean
}

interface InvoiceCellProps {
  right?: boolean
  border?: boolean
  onBlur?: (event) => void
}

const BorderedDataTableCell = styled(
  DataTableCell as React.FunctionComponent<DataTableCellProps>
)`
  && {
    border-right: 1px solid rgba(0, 0, 0, 0.12);
    border-bottom: 1px solid rgba(0, 0, 0, 0.12);
    padding: 0;

    &:first-of-type {
      padding-left: 0;
      width: 50%;

      input {
        text-align: left;
      }
    }

    &:last-of-type {
      border-right: none;
      padding-right: 0;
    }

    input,
    div {
      height: 100%;
      padding: 24px;
      text-align: center;
    }
  }
`

export const InvoiceCell = styled.div<InvoiceCellProps>`
  width: 100%;
  height: 100%;
  /* background-color: white; */
  min-height: 40px;
  font-size: 14px;
  font-weight: 600;
  padding: 0 8px;
  display: flex;
  flex-direction: ${props => (props.right ? "row-reverse" : "row")};
  align-items: center;
`

const StyledInvoiceInput = styled.input<InvoiceCellProps>`
  font-size: 14px;
  width: 100%;
  height: 40px;
  padding: 0 8px;
  border: none;
  display: flex;
  text-align: ${props => (props.right ? "right" : "left")};

  ::placeholder {
    color: ${props => props.theme.primary};
  }

  &:focus {
    outline: none;
  }
`

const LineItem = ({ item }) => {
  const { dispatch } = useCreateInvoice()

  return (
    <DataTableRow>
      <BorderedDataTableCell>
        <StyledInvoiceInput
          value={item.title}
          placeholder="Title"
          onChange={event =>
            dispatch({
              type: "updateItem",
              item: { ...item, title: event.target.value },
            })
          }
          onFocus={event => event.target.select()}
        />
      </BorderedDataTableCell>
      <BorderedDataTableCell alignMiddle>
        <StyledInvoiceInput
          placeholder="Price"
          value={item.price}
          onChange={event =>
            dispatch({
              type: "updateItem",
              item: { ...item, price: event.target.value },
            })
          }
          onFocus={event => event.target.select()}
        />
      </BorderedDataTableCell>
      <BorderedDataTableCell alignEnd>
        <StyledInvoiceInput
          placeholder="Copies"
          value={item.copies}
          onChange={event =>
            dispatch({
              type: "updateItem",
              item: { ...item, copies: event.target.value },
            })
          }
          onFocus={event => event.target.select()}
        />
      </BorderedDataTableCell>
      <BorderedDataTableCell>
        <div>${item.total}</div>
      </BorderedDataTableCell>
      <BorderedDataTableCell>
        <IconButton
          onClick={() => dispatch({ type: "removeItem", item })}
          icon="close"
        />
      </BorderedDataTableCell>
    </DataTableRow>
  )
}

/////////////////////////////////////////////////////
/////////////////////////////////////////////////////
// TOTAL
/////////////////////////////////////////////////////
/////////////////////////////////////////////////////

const Actions = styled.div`
  display: flex;
  justify-content: flex-end;

  button {
    margin-left: 16px;
  }
`

export const TotalWrapper = styled.div`
  display: flex;
  flex-direction: row-reverse;
  width: 100%;
  margin-top: 24px;
`
export const TotalBox = styled.div`
  border-top: none;
  width: 35%;
  display: grid;
  grid-template-columns: 1fr 1fr;
`

const Total = () => {
  const { state, dispatch } = useCreateInvoice()
  const { total, shipping, displayConfirmation } = state

  return (
    <>
      <TotalWrapper>
        <TotalBox>
          <InvoiceCell right={true}>Subtotal:</InvoiceCell>
          <InvoiceCell>${total.subtotal}</InvoiceCell>

          <InvoiceCell right={true}>Shipping:</InvoiceCell>
          <StyledInvoiceInput
            value={shipping}
            placeholder="Shipping"
            onChange={event =>
              dispatch({ type: "updateShipping", shipping: event.target.value })
            }
            onFocus={event => event.target.select()}
          />

          <InvoiceCell right={true}>Tax:</InvoiceCell>
          <InvoiceCell>${total.tax}</InvoiceCell>

          <InvoiceCell right={true}>
            <Subhead1>Total:</Subhead1>
          </InvoiceCell>

          <InvoiceCell>
            <Subhead1>${total.total}</Subhead1>
          </InvoiceCell>
        </TotalBox>
      </TotalWrapper>
      <Seperator />

      <Actions>
        <Button icon="close" onClick={() => navigate("./")} variant="outlined">
          Cancel
        </Button>
        <Button
          disabled={displayConfirmation}
          onClick={() =>
            dispatch({
              type: "displayConfirmation",
              displayConfirmation: true,
            })
          }
        >
          Charge customer
        </Button>
      </Actions>
    </>
  )
}

export default CreateInvoice
