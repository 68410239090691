import * as React from "react"
import { Link } from "@reach/router"
import styled from "styled-components"
import { Button } from "./rmwc/Button/Button"
import useMutation from "../hooks/useMutation"
import useFirestore from "../hooks/useFirestore"
import { TextField } from "./rmwc/TextField"
import { Seperator } from "./rmwc/Seperator"
import { Headline2, Headline1 } from "./rmwc/Typography"
import { Icon } from "@rmwc/icon"
import {
  StyledList,
  ListHeadline,
  Layout,
  SearchContainer,
  SearchInput,
  ListWrapper,
  Text,
  ListItem,
  SearchIcon,
} from "./ListElements"

const UserList: React.FC = React.memo(() => {
  const [searchInput, setSearchInput] = React.useState("")

  const { data: users, loading } = useFirestore({
    collection: "users",
    orderBy: ["box", "asc"],
  })

  return (
    <StyledList>
      <ListHeadline>Users</ListHeadline>
      <Layout>
        <SearchContainer>
          <SearchInput
            placeholder="Search users"
            value={searchInput}
            onChange={event => setSearchInput(event.target.value)}
          />
          <SearchIcon icon="search" />
        </SearchContainer>

        {!loading && (
          <ListWrapper>
            {users
              .filter(
                user =>
                  user.displayName
                    .toLowerCase()
                    .includes(searchInput.toLowerCase()) ||
                  user.box == searchInput
              )
              .map(user => (
                <ListItem
                  to={`/admin/users/${user.uid}/profile`}
                  key={user.uid}
                >
                  <Text>{user.displayName}</Text>
                  <Text>{user.box}</Text>
                </ListItem>
              ))}
          </ListWrapper>
        )}
      </Layout>
    </StyledList>
  )
})

export default UserList
