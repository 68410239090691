import * as React from "react"
import loadable from "@loadable/component"
import { Router, Link, Redirect, RouteComponentProps } from "@reach/router"
import { useCurrentUser } from "../hooks/useCurrentUser"

const Upload = loadable(() => import("../views/admin/Upload"))
const PageWrapper = loadable(() => import("../pageWrapper"))

import UserDashboard from "../views/admin/userDashboard"
import Users from "../views/admin/users"
import User from "../views/admin/user"
import styled from "styled-components"
import { IconButton } from "../components/rmwc/IconButton"
import Profile from "../views/users/Profile"
import Pulls from "../views/users/Pulls"
import Activity from "../views/users/Activity"
import Invoices from "../views/users/Invoices"
import Invoice from "../views/users/Invoice"
import CreateInvoice from "../views/users/CreateInvoice"
import Logo from "../components/logo"
import ReadyIssues from "../views/admin/ReadyIssues"
import ReadyIssue from "../views/admin/ReadyIssue"
import { useFirestoreRef } from "../hooks/useFirestore"
import OrderDashboard from "../views/admin/OrderDashboard"
import FOCs from "../views/admin/FOCs"
import FOC from "../views/admin/FOC"
import UserComic from "../views/admin/UserComic"

const AdminLayout = styled.div`
  display: flex;
  flex: 1;
`

const AdminSidebar = styled.div`
  width: 64px;
  height: 100vh;
  position: fixed;
`

const NavItem = styled(Link)`
  color: var(--mdc-theme-text-hint-on-background);
`

const LogoWrapper = styled.div`
  width: 100%;
  padding-top: 74px;
  padding-bottom: 44px;
  display: flex;
  justify-content: center;
`

const Nav = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
`

const RouterDiv = styled.div`
  display: flex;
  padding-left: 64px;
  flex: 1;
`

const Admin = () => {
  const { currentUser, loading } = useCurrentUser()

  if (loading) return null
  // if (!currentUser || !currentUser.admin) {
  //   return <Redirect to="/" noThrow />
  // }

  return (
    <PageWrapper>
      <AdminLayout>
        <AdminSidebar>
          <LogoWrapper>
            <NavItem to="/">
              <Logo />
            </NavItem>
          </LogoWrapper>
          <Nav>
            <NavItem to="/admin/users">
              <IconButton icon="supervisor_account" />
            </NavItem>

            <NavItem to="/admin/foc">
              <IconButton icon="calendar_today" />
            </NavItem>

            <NavItem to="/admin/ready-issues">
              <IconButton icon="menu_book" />
            </NavItem>

            <NavItem to="/admin/upload">
              <IconButton icon="cloud_upload" />
            </NavItem>
          </Nav>
        </AdminSidebar>

        <Router component={RouterDiv}>
          <Users path="/admin/users/">
            <UserDashboard path="/" />
            <User path=":id">
              <Profile path="profile" />
              <Pulls path="pulls" />
              <CreateInvoice path="invoices/create" />
              <Invoices path="invoices" />
              <Invoice path="invoices/:id" />
              <UserComic path="comics/:seriesCode" />
              <Activity path="activity" />
            </User>
          </Users>

          <ReadyIssues path="/admin/ready-issues">
            <ReadyIssue path=":stockNumber" />
          </ReadyIssues>

          <FOCs path="/admin/foc">
            <OrderDashboard path="/" />
            <FOC path=":date" />
          </FOCs>

          <Upload path="/admin/upload" />
        </Router>
      </AdminLayout>
    </PageWrapper>
  )
}

export default Admin
