import * as React from "react"
import { Form } from "../Form"
import { UpdateProps, UpdateInput } from "./UpdateTemplate"
import { auth } from "firebase"
import useMutation from "../../hooks/useMutation"

export const UpdatePassword: React.FC<UpdateProps> = props => {
  const { active, onCancel, user } = props
  const [password, updatePasswordInput] = React.useState("")
  const [confirm, updateConfirm] = React.useState("")
  const [customError, setError] = React.useState("")

  const handleSubmit = () => {
    // TODO: UPDATE PASSWORD?!
    // const { success, error } = useMutation({
    //   collection: "users"
    // })
    // if (success) {
    //   setError("")
    //   onCancel()
    // } else {
    //   setError("Passwords do not match")
    // }
  }

  return (
    <Form
      // disabled={loading}
      onSubmit={handleSubmit}
      submitText="Save"
      active={active}
      // message={customError || (error && error.message)}
    >
      <UpdateInput
        state={[password, updatePasswordInput]}
        disabled={!active}
        label="New password"
      />
      <UpdateInput
        state={[confirm, updateConfirm]}
        disabled={!active}
        label="Confirm new password"
      />
    </Form>
  )
}
