import * as React from "react"
import * as axios from "axios"
import { Form } from "../Form"
import { FUNCTION_PATH } from "../../core/constants"
import { useStripe } from "../../hooks/StripeHookProvider"
import { CardElement } from "react-stripe-elements"
import { Body1, Body2, BodySmall } from "../../components/rmwc/Typography"
import styled from "styled-components"
import useFirestore from "../../hooks/useFirestore"

const CC = styled.div`
  background-color: #333;
  background: linear-gradient(to bottom, #222222 0%, #5b5b5b 100%);
  padding: 0 24px;
  padding-top: 58%;
  border-radius: 12px;
  position: relative;
  display: flex;
  justify-content: center;
  position: relative;
`

const Number = styled(Body2)`
  color: white;
  bottom: 33%;
  left: 24px;
  letter-spacing: 0.1em;
  position: absolute;
`

const CardDetails = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  bottom: 20%;
  padding: 0 24px;
  position: absolute;
`

const DetailText = styled(BodySmall)`
  color: white;
`

interface PaymentProps {
  user: any
  active?: boolean
  onCancel: () => void
}

export const UpdatePayment: React.FC<PaymentProps> = props => {
  const stripe = useStripe()
  const { active, user, onCancel } = props
  const [error, setError] = React.useState("")
  const [processing, setProcessing] = React.useState(false)

  const { data: card, loading } = useFirestore({
    collection: "stripeAccounts",
    find: user.uid,
  })

  const handleSubmit = async event => {
    event.preventDefault()
    setProcessing(true)
    const stripeResponse = await stripe.createToken()

    if (stripeResponse.error) {
      setError(stripeResponse.error.message)
    } else {
      axios
        .post(`${FUNCTION_PATH}/updateStripeAccount`, {
          tokenId: stripeResponse.token.id,
          uid: user.uid,
          stripeId: card.stripeId,
        })
        .then(() => {
          setError("")
          setProcessing(false)
          onCancel()
        })
        .catch(error => {
          setError(error.response.data)
          setProcessing(false)
        })
    }
  }

  if (loading) return null

  return (
    <>
      {!active && (
        <CC>
          <Number>**** - **** - **** - {card.last4}</Number>
          <CardDetails>
            <DetailText>{user.displayName}</DetailText>
            <DetailText>
              {card.exp_month}/{card.exp_year}
            </DetailText>
            <DetailText>{card.brand}</DetailText>
          </CardDetails>
        </CC>
      )}
      <Form
        disabled={processing}
        onSubmit={handleSubmit}
        submitText="Update payment"
        message={error}
        messageType={"error"}
        active={active}
      >
        {active && <CardElement />}
      </Form>
    </>
  )
}
