import * as React from "react"
import { RouteComponentProps } from "@reach/router"
import { IconButton } from "../../components/rmwc/IconButton"
import { FUNCTION_PATH } from "../../core/constants"

const OrderDashboard: React.FC<RouteComponentProps> = props => {
  const updateReadyIssues = () => {
    fetch(`${FUNCTION_PATH}/createOrders`)
  }

  return <div>Orders</div>
}

export default OrderDashboard
