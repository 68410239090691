import * as React from "react"
import { RouteComponentProps, Link } from "@reach/router"
import UserList from "../../components/UserList"
import { DetailWrapper, Content } from "../../components/DetailElements"

const Users: React.FC<RouteComponentProps> = props => {
  const { children } = props

  return (
    <DetailWrapper>
      <UserList />
      <Content>{children}</Content>
    </DetailWrapper>
  )
}

export default Users
