import * as React from "react"
import { RouteComponentProps } from "@reach/router"
import useFirestore from "../../hooks/useFirestore"
import moment from "moment"
import { Link } from "gatsby"
import {
  Layout,
  SearchContainer,
  SearchInput,
  ListWrapper,
} from "../../components/ListElements"
import { Icon } from "@rmwc/icon"
import { IconButton } from "../../components/rmwc/IconButton"
import { FUNCTION_PATH } from "../../core/constants"
import {
  Content,
  DetailHeader,
  MainContent,
  DisplayName,
  DetailContent,
  DetailList,
  DetailListHeader,
  DetailListCell,
  DetailListItem,
} from "../../components/DetailElements"
import { coverConvert } from "../../core/Utils"
import { firestore } from "../../core/firebase"
import { Container } from "../../components/Container"

const FOC: React.FC<RouteComponentProps & { date?: string }> = props => {
  const { date } = props
  const dateInt = parseInt(props.date)

  const { data: orders, loading } = useFirestore({
    collection: "orders",
    where: [["printDate", "==", dateInt]],
    update: dateInt,
  })

  if (loading) return null

  return (
    <>
      <DetailHeader>
        <Container>
          <MainContent>
            <DisplayName>
              FOC due on {moment(dateInt).format("MMM Do, YYYY")}
            </DisplayName>
          </MainContent>
        </Container>
      </DetailHeader>
      <DetailContent>
        <Container>
          <DetailList>
            <DetailListHeader>
              <DetailListCell flex={2}>Title</DetailListCell>
              <DetailListCell>Stock Number</DetailListCell>
              <DetailListCell right>Copies</DetailListCell>
            </DetailListHeader>
            {orders.map(order => (
              <OrderIssuesListItem order={order} />
            ))}
          </DetailList>
        </Container>
      </DetailContent>
    </>
  )
}

const OrderIssuesListItem: React.FC<{ order: any }> = ({ order }) => {
  return (
    <Link to={`/admin/ready-issues/${order.stockNumber}`}>
      <DetailListItem key={order.id}>
        <DetailListCell flex={2}>
          {order.title} #{order.issue} {coverConvert(order.cover)}
        </DetailListCell>
        <DetailListCell>{order.stockNumber}</DetailListCell>
        <DetailListCell right>{order.totalcopies}</DetailListCell>
      </DetailListItem>
    </Link>
  )
}

export default FOC
