import * as React from "react"
import styled from "styled-components"
import { RouteComponentProps } from "@reach/router"
import { firestore } from "../../core/firebase"
import * as moment from "moment"
import { Container } from "../../components/Container"
import {
  MainContent,
  DetailHeader,
  DisplayName,
  DetailContent,
} from "../../components/DetailElements"
import { Card } from "../../components/Card"
import { Body1, Subhead2 } from "../../components/rmwc/Typography"
import { CardContainer, CardCell, CardSpacer } from "../users/Profile"
import useSnapshot from "../../hooks/useSnapshot"
import { NOW } from "../../core/constants"

const InvoiceTotals = () => {
  const [subtractWeeks, setSubtractWeeks] = React.useState(2)
  const { data: invoices, loading } = useSnapshot(
    firestore
      .collection("invoices")
      .where(
        "date",
        "<=",
        moment(NOW)
          .subtract(subtractWeeks, "week")
          .endOf("month")
          .valueOf()
      )
      .where(
        "date",
        ">=",
        moment(NOW)
          .subtract(subtractWeeks, "week")
          .startOf("month")
          .valueOf()
      )
  )

  let subtotal = 0
  let shipping = 0
  let tax = 0
  let total = 0

  if (!loading) {
    subtotal = invoices.reduce((inc, invoice) => inc + +invoice.subtotal, 0)
    shipping = invoices.reduce((inc, invoice) => inc + +invoice.shipping, 0)
    tax = invoices.reduce((inc, invoice) => inc + +invoice.tax, 0)
    total = invoices.reduce((inc, invoice) => inc + +invoice.total, 0)
  }

  return (
    <CardCell>
      <Card title="Totals">
        <Subhead2>
          <b>Subtotal:</b> ${subtotal.toFixed(2)}
        </Subhead2>
        <Subhead2>
          <b>Shipping:</b> ${shipping.toFixed(2)}
        </Subhead2>

        <Subhead2>
          <b>Tax:</b> ${tax.toFixed(2)}
        </Subhead2>

        <Subhead2>
          <b>Total:</b> ${total.toFixed(2)}
        </Subhead2>
      </Card>
    </CardCell>
  )
}

const PullTotals = () => {
  const { data: stats, loading } = useSnapshot(
    firestore.collection("pulls").doc("--stats--")
  )

  return (
    <CardCell>
      <Card title="Pulls">
        <Subhead2>
          <b>Pulls:</b> {stats.pullCount}
        </Subhead2>
      </Card>
    </CardCell>
  )
}

const UserDashboard: React.FC<RouteComponentProps> = () => {
  return (
    <>
      <DetailHeader>
        <Container>
          <MainContent>
            <DisplayName>Dashboard</DisplayName>
          </MainContent>
        </Container>
      </DetailHeader>
      <DetailContent>
        <CardContainer>
          <InvoiceTotals />
          <CardSpacer />
          <PullTotals />
        </CardContainer>
      </DetailContent>
    </>
  )
}

export default UserDashboard
