import * as React from "react"
import { Form } from "../Form"
import { UpdateProps, UpdateInput } from "./UpdateTemplate"
import { useCurrentUser } from "../../hooks/useCurrentUser"
import useMutation from "../../hooks/useMutation"

export const UpdateProfile: React.FC<UpdateProps> = props => {
  const { active, onCancel, user } = props
  const { currentUser, currentUserContext } = useCurrentUser()
  const [loading, setLoading] = React.useState(false)

  const [displayName, setDisplayName] = React.useState("")
  const [phoneNumber, setPhoneNumber] = React.useState("")
  const [email, setEmail] = React.useState("")
  const [box, setBox] = React.useState("")

  React.useEffect(() => {
    setDisplayName(user.displayName)
    setPhoneNumber(user.phoneNumber)
    setEmail(user.email)
    setBox(user.box)
  }, [user])

  const handleSubmit = async () => {
    setLoading(true)
    const update = await useMutation({
      collection: "users",
      update: {
        id: user.uid,
        displayName,
        phoneNumber,
        email,
        box: parseInt(box),
      },
    })

    if (update.error) {
    }

    if (update.success) {
      onCancel()
    }

    setLoading(false)
  }

  if (!user || !currentUser) return null

  return (
    <Form
      active={active}
      disabled={loading}
      onSubmit={handleSubmit}
      submitText="Save"
    >
      <UpdateInput
        label="Name"
        state={[displayName, setDisplayName]}
        disabled={!active}
      />
      <UpdateInput label="Email" state={[email, setEmail]} disabled={!active} />
      <UpdateInput
        label="Phone"
        state={[phoneNumber, setPhoneNumber]}
        disabled={!active}
      />
      {currentUser.admin && (
        <UpdateInput label="Box" state={[box, setBox]} disabled={!active} />
      )}
    </Form>
  )
}
