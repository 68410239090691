import * as React from "react"
import styled from "styled-components"
import { TextField as MaterialTextField } from "@rmwc/textfield"
import "@material/textfield/dist/mdc.textfield.css"
import "@material/floating-label/dist/mdc.floating-label.css"
import "@material/notched-outline/dist/mdc.notched-outline.css"
import "@material/line-ripple/dist/mdc.line-ripple.css"
import { ComponentProps } from "@rmwc/types"

export const StyledTextField = styled(MaterialTextField)`
  &&& {
    background-color: white;
    height: ${p => p.dense && "40px"};
  }
`

export interface InputProps {
  className?: string
  dense?: boolean
  disabled?: boolean
  label?: string
  pattern?: string
  placeholder?: string
  required?: boolean
  state: [string, (value) => void]
  type?: string
}

export const TextField: React.FC<
  InputProps & Pick<ComponentProps, "className">
> = props => {
  const { className, state, placeholder } = props
  const value = state[0]
  const setValue = state[1]

  return (
    <StyledTextField
      className={className}
      outlined={true}
      placeholder={placeholder}
      value={value}
      onChange={({ currentTarget: { value } }) => setValue(value)}
      {...props}
    />
  )
}
