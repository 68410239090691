import * as React from "react"

const Logo = () => {
  return (
    <svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
      <g
        id="Admin-dashboard"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g id="Artboard">
          <g id="logo">
            <rect
              id="Rectangle"
              stroke="#FFFFFF"
              fill="#FFFFFF"
              x="0.5"
              y="0.5"
              width="23"
              height="23"
              rx="11.5"
            ></rect>
            <g id="LOGO" transform="translate(1.734375, 1.734375)">
              <path
                d="M10.265625,0 C15.9351731,-1.0414791e-15 20.53125,4.59607687 20.53125,10.265625 C20.53125,15.9351731 15.9351731,20.53125 10.265625,20.53125 C4.59607687,20.53125 4.58994384e-13,15.9351731 4.54747351e-13,10.265625 C4.54053031e-13,4.59607687 4.59607687,1.0414791e-15 10.265625,0 Z M0.721801758,9.30322266 L3.38219261,13.0726318 L9.51876068,11.6891785 L0.721801758,9.30322266 Z M19.6640854,9.42926789 L10.8671265,11.8152237 L17.0358059,13.0726318 L19.6640854,9.42926789 Z"
                id="Combined-Shape"
                fill="#212121"
              ></path>
              <path
                d="M10.265625,0 C14.3823469,-7.56229544e-16 17.9330946,2.42322337 19.569299,5.9211008 L12.3027413,8.27324607 L11.2681274,7.03410585 L10.5608421,9.9375 L9.75435593,4.07717994 L8.1636166,8.27324607 L0.855063425,6.15737582 C2.43962188,2.53282036 6.05683749,7.73141723e-16 10.265625,0 Z"
                id="Combined-Shape"
                fill="#3388FF"
              ></path>
            </g>
          </g>
        </g>
      </g>
    </svg>
  )
}

export default Logo
