import * as React from "react"
import { Form } from "../Form"
import { UpdateProps, UpdateInput } from "./UpdateTemplate"
import useFirestore from "../../hooks/useFirestore"
import useMutation from "../../hooks/useMutation"

interface Address {
  apt: string
  street: string
  city: string
  state: string
  zip: string
}

export const UpdateAddress: React.FC<UpdateProps> = props => {
  const { user, active, onCancel } = props
  const [apt, updateApt] = React.useState("")
  const [street, updateStreet] = React.useState("")
  const [city, updateCity] = React.useState("")
  const [state, updateState] = React.useState("")
  const [zip, updateZip] = React.useState("")

  const { data: address } = useFirestore({
    collection: "addresses",
    find: user.uid,
  })

  React.useEffect(() => {
    const { apt, street, city, state, zip } = address
    updateApt(apt)
    updateStreet(street)
    updateCity(city)
    updateState(state)
    updateZip(zip)
  }, [address])

  const handleSubmit = async () => {
    const { success, error } = await useMutation({
      collection: "addresses",
      update: {
        id: user.uid,
        apt,
        street,
        city,
        state,
        zip,
      },
    })

    if (success) {
      onCancel()
    }
  }

  return (
    <Form
      disabled={false}
      onSubmit={handleSubmit}
      submitText="Save"
      active={active}
    >
      <UpdateInput label="Apt" state={[apt, updateApt]} disabled={!active} />
      <UpdateInput
        label="Street"
        state={[street, updateStreet]}
        disabled={!active}
      />
      <UpdateInput label="City" state={[city, updateCity]} disabled={!active} />
      <UpdateInput
        label="State"
        state={[state, updateState]}
        disabled={!active}
      />
      <UpdateInput label="Zip" state={[zip, updateZip]} disabled={!active} />
    </Form>
  )
}
