import React, {
  createContext,
  useContext,
  useReducer,
  useRef,
  useEffect,
} from "react"
import * as axios from "axios"
import useFirestore from "../../hooks/useFirestore"
import { UserContext } from "./User"
import { FUNCTION_PATH } from "../../core/constants"
import { useSet, useDelete } from "../../hooks/useMutation"

const DEFAULT_STATE = {}

const reducer = (state, action) => {
  const updateTotal = () => {
    const subtotal = state.lineItems.reduce(
      (total, item) => total + item.total,
      0
    )

    const totalCount = state.lineItems.reduce(
      (total, item) => total + parseInt(item.copies),
      0
    )

    const tax = subtotal * 0.1
    const total = +subtotal + +tax + +action.shipping
    console.log("SUBTOTAL :", subtotal)

    return {
      subtotal: subtotal.toFixed(2),
      tax: tax.toFixed(2),
      total: total.toFixed(2),
      totalCount: totalCount,
    }
  }

  switch (action.type) {
    case "addItem":
      const item = action.item || {
        id: state.lineItems.length,
        title: "Title",
        price: 3.99,
        copies: 1,
        total: 3.99,
      }

      state.lineItems.push(item)

      return {
        ...state,
        total: updateTotal(),
      }

    case "updateItem":
      action.item.total = +action.item.price * +action.item.copies || 1
      const index = state.lineItems.findIndex(item => item.id == action.item.id)
      state.lineItems[index] = action.item

      return {
        ...state,
        total: updateTotal(),
      }

    case "updateShipping":
      return {
        ...state,
        shipping: action.shipping,
        total: updateTotal(),
      }

    case "removeItem":
      state.lineItems = state.lineItems.filter(
        item => item.id !== action.item.id
      )
      return {
        ...state,
        total: updateTotal(),
      }

    case "displayConfirmation":
      return {
        ...state,
        error: false,
        displayConfirmation: action.displayConfirmation,
      }

    case "invoiceCreateStart":
      return {
        ...state,
        processingCharge: true,
      }

    case "invoiceCreateError":
      return {
        ...state,
        processingCharge: false,
        error: action.error,
      }

    case "invoiceCreateSuccess":
      return {
        ...state,
        processingCharge: false,
        invoiceId: action.id,
      }

    default:
      console.warn("invalid action type")
      return DEFAULT_STATE
  }
}

const CreateInvoiceContext = createContext(null)

export const CreateInvoiceProvider = ({ children }) => {
  const CreateInvoiceState = useReducer(reducer, {
    total: {
      subtotal: 0,
      tax: 0,
      total: 0,
      totalCount: 0,
    },
    shipping: 0,
    lineItems: [],
    displayConfirmation: false,
    loaded: false,
    invoiceId: false,
    error: false,
    processingCharge: false,
  })

  return (
    <CreateInvoiceContext.Provider value={CreateInvoiceState}>
      {children}
    </CreateInvoiceContext.Provider>
  )
}

const useCreateInvoice = () => {
  const user = React.useContext(UserContext)
  const [state, dispatch] = useContext(CreateInvoiceContext)

  // TODO: this should store stripeId directly on the user instead of pulling just the id
  const { data: stripeAccount, loading: stripeLoading } = useFirestore({
    collection: "stripeAccounts",
    find: user.uid,
  })

  const { data: inboxitems, loading } = useFirestore({
    collection: "tempInboxitems",
    where: [["email", "==", user.email]],
  })

  const { data: draftInvoice, loading: draftLoading } = useFirestore({
    collection: "draftInvoices",
    find: user.uid,
  })

  const [deleteDraftMutation] = useDelete({
    collection: "draftInvoices",
  })

  if (!loading && !state.loaded && !draftLoading) {
    state.loaded = true

    if (draftInvoice && draftInvoice.lineItems) {
      draftInvoice.lineItems.map(item => {
        dispatch({ type: "addItem", item: item })
      })
    } else {
      inboxitems.map((item, index) => {
        item.id = index
        item.price = parseFloat(item.price || 3.99).toFixed(2)
        item.copies = 1
        item.total = item.price * item.copies
        dispatch({ type: "addItem", item })
      })
    }
  }

  const createCharge = () => {
    dispatch({ type: "invoiceCreateStart" })

    axios
      .post(`${FUNCTION_PATH}/createInvoice`, {
        state: state,
        uid: user.uid,
        stripeId: stripeAccount.stripeId,
      })
      .then(async response => {
        const deleteResponse = await deleteDraftMutation(user.uid)
        console.log("DELETE RESPNSE", deleteResponse)

        dispatch({
          type: "invoiceCreateSuccess",
          id: response.data.invoiceId,
        })
      })
      .catch(error => {
        dispatch({ type: "invoiceCreateError", error: error.response.data })
      })
  }

  return { state, loading, dispatch, createCharge }
}

export default useCreateInvoice
