import * as React from "react"
import { RouteComponentProps, Link } from "@reach/router"
import { Container } from "../../components/Container"
import { Headline1, Headline2 } from "../../components/rmwc/Typography"
import { UserContext } from "./User"
import {
  FlexContainer,
  FlexList,
  SearchInput,
  SearchContainer,
  SearchIcon,
} from "../../components/ListElements"
import { SimpleListItem } from "@rmwc/list"
import { Menu, MenuSurface, MenuSurfaceAnchor, MenuItem } from "@rmwc/menu"
import moment from "moment"
import { DATE_FORMAT } from "../../core/constants"
import { firestore } from "../../core/firebase"
import useSnapshot from "../../hooks/useSnapshot"
import useFirestore from "../../hooks/useFirestore"
import { useSet } from "../../hooks/useMutation"
import { CurrentUserContext } from "../../hooks/CurrentUserContext"
import { IconButton } from "../../components/rmwc/IconButton"

const Pulls: React.FC<RouteComponentProps> = props => {
  const { currentUser } = React.useContext(CurrentUserContext)
  const user = React.useContext(UserContext)
  const { data: pulls, loading } = useSnapshot(
    firestore
      .collection("pulls")
      .where("uid", "==", user.uid)
      .orderBy("active", "desc")
      .orderBy("title", "asc")
  )

  let comicLink = ""
  if (currentUser.admin) {
    comicLink = `/admin/users/${user.uid}/comics`
  } else {
    comicLink = `/comics`
  }

  return (
    <FlexContainer direction="column">
      <Headline2 color="primary">Pulls</Headline2>
      {currentUser.admin && <AddPullSearchBox />}
      <FlexList twoLine>
        {!loading &&
          pulls.map(pull => (
            <Link to={`${comicLink}/${pull.seriesCode}`} key={pull.id}>
              <SimpleListItem
                text={pull.title}
                secondaryText={
                  pull.active
                    ? `Active: Created on ${moment(pull.createdOn).format(
                        DATE_FORMAT
                      )}`
                    : `Inactive: Cancelled on ${moment(pull.cancelledOn).format(
                        DATE_FORMAT
                      )}`
                }
              />
            </Link>
          ))}
      </FlexList>
    </FlexContainer>
  )
}

export default Pulls

const AddPullSearchBox: React.FC = () => {
  const user = React.useContext(UserContext)
  const [input, setInput] = React.useState("")
  const [results, setResults] = React.useState([])

  const { data: comics, loading } = useFirestore({
    collection: "comics",
    orderBy: ["title", "asc"],
  })

  const handleInput = event => {
    setInput(event.target.value)
    setResults(
      comics.filter(comic =>
        comic.title.toLowerCase().includes(input.toLowerCase())
      )
    )
  }

  const handleSelect = async event => {
    setInput("")
    const comic = results[event.detail.index]

    await firestore
      .collection("pulls")
      .doc(`${user.uid}-${comic.seriesCode}`)
      .set(
        {
          active: true,
          covers: {
            default: {
              0: 1,
            },
          },
          seriesCode: comic.seriesCode,
          title: comic.title,
          uid: user.uid,
          createdOn: moment().valueOf(),
        },
        { merge: true }
      )
      .catch(error => {
        console.log("error :", error)
      })
  }

  if (loading) return null

  return (
    <>
      <SearchContainer>
        <SearchInput
          onChange={handleInput}
          value={input}
          placeholder="Add pull"
        />
        <SearchIcon icon="search" />
      </SearchContainer>

      <MenuSurfaceAnchor>
        <Menu
          anchorCorner="bottomLeft"
          focusOnOpen={false}
          open={input.length > 3}
          onSelect={handleSelect}
          style={{ width: "100%" }}
        >
          {results.map(comic => (
            <MenuItem>{comic.title}</MenuItem>
          ))}
        </Menu>
      </MenuSurfaceAnchor>
    </>
  )
}
