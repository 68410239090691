import * as React from "react"
import * as moment from "moment"
import { RouteComponentProps, navigate } from "@reach/router"
import { List, SimpleListItem } from "@rmwc/list"
import styled from "styled-components"

import useFirestore from "../../hooks/useFirestore"
import { Seperator } from "../../components/rmwc/Seperator"
import {
  Subhead2,
  Headline1,
} from "../../components/rmwc/Typography/Typography"
import { Card } from "../../components/Card"
import { Container } from "../../components/Container"
import { UpdateInput } from "../../components/forms/UpdateTemplate"
import { useUpdate, useDelete } from "../../hooks/useMutation"
import { IconButton } from "../../components/rmwc/IconButton"
import { Button } from "../../components/rmwc/Button"
import { DATE_FORMAT } from "../../core/constants"

const TrackingHeadline = styled(Headline1)`
  color: ${p => p.theme.primary};
`

const TrackingRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`

const TrackingInput = styled(UpdateInput)`
  margin-bottom: 0;
`

const Tracking = ({ tracking, invoiceId }) => {
  const [trackingInput, setTrackingInput] = React.useState(tracking)
  const [active, setActive] = React.useState(false)

  const [addTracking, { loading, error }] = useUpdate({
    collection: "invoices",
    data: {
      tracking: trackingInput,
    },
  })

  const handleSubmit = async event => {
    event.preventDefault()
    const result = await addTracking(invoiceId)

    if (result.success) {
      setActive(false)
    }
  }

  return (
    <>
      {active && (
        <form onSubmit={handleSubmit}>
          {error && <div>{error}</div>}
          <TrackingRow>
            <TrackingInput
              state={[trackingInput, setTrackingInput]}
              label="Tracking"
              disabled={loading}
              fullWidth
            />
            <IconButton icon="save" />
          </TrackingRow>
        </form>
      )}
      {!active && (
        <>
          <Subhead2>Tracking number:</Subhead2>
          <TrackingRow>
            <TrackingHeadline>{tracking}</TrackingHeadline>
            <IconButton icon="edit" onClick={() => setActive(true)} />
          </TrackingRow>
        </>
      )}
    </>
  )
}

//
//
//

const Invoice: React.FC<RouteComponentProps<{ id: string }>> = props => {
  const { id } = props

  const { data: invoice, loading } = useFirestore({
    collection: "invoices",
    find: id,
  })

  const [deleteInvoice] = useDelete({
    collection: "invoices",
  })

  if (loading) return null

  const handleDelete = async () => {
    deleteInvoice(id)
    navigate("./")
  }

  return (
    <Container>
      <Card
        title={`Alter Ego Invoice - ${moment(invoice.date).format(
          DATE_FORMAT
        )}`}
        onAction={() => navigate("./")}
        actionIcon="close"
      >
        <Tracking tracking={invoice.tracking} invoiceId={invoice.id} />
        <Seperator />
        <List twoLine>
          {invoice.lineItems.map(item => (
            <SimpleListItem
              text={item.title}
              secondaryText={item.copies + " copies"}
              meta={"$" + item.total}
              key={item.id}
            />
          ))}
        </List>
        <Seperator />

        <List>
          <SimpleListItem text="Subtotal" meta={"$" + invoice.subtotal} />
          <SimpleListItem text="Tax" meta={"$" + invoice.tax} />
          <SimpleListItem text="Shipping" meta={"$" + invoice.shipping} />
          <SimpleListItem text="TOTAL" meta={"$" + invoice.total} />
        </List>
        <Button onClick={() => handleDelete()}>Delete</Button>
      </Card>
    </Container>
  )
}

export default Invoice
