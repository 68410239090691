import * as React from "react"
import styled from "styled-components"
import { TextField } from "../rmwc/TextField"

export interface UpdateProps {
  active?: boolean
  onCancel?: () => void
  user: any
}

interface Props {
  dense?: boolean
  fullWidth?: boolean
}

export const UpdateInput = styled(TextField)<Props>`
  width: ${p => p.fullWidth && "100%"};
  height: ${p => p.dense && "40px"};
  margin-bottom: 16px;

  .mdc-text-field__input {
    color: rgba(0, 0, 0, 0.8);
  }
`
